// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Page6 = (props: PageProps) => (
  
  <Layout backgroundColor="rgb(251,251,245)">
    <SEO title="Sketch #9" />
    {/* <Link to="/">Back</Link>   */}


    <div className="flex justify-center">
      <div className="w-full ">
        <iframe src="https://yanngraf.github.io/codesbars/009_codebars-paper-cache-embed/" width="100%" height="800"></iframe>
      </div>
    </div>

    
  </Layout>
)

export default Page6